<template>
  <AcquisitionsManagerStyled :isAnyAcquisition="isAnyAcquisition">
    <div class="title">
      {{ $tc('acquisition', 2) }}
    </div>
    <div class="acquisitions" v-if="isAnyAcquisition">
      <OrganizationAcquisitionListMolecule
        :acquisitions="acquisitions"
        @deleteAcquisition="confirmDeleteAcquisition"
        @editAcquisition="activateEditAcquisitionOverlay"
      />
    </div>
    <div class="acquisitions-placeholder" v-else>
      {{ $tc('messages.noAcquisition') }}
    </div>
    <div class="create">
      <div class="add-icon" @click="activateAddAcquisitionOverlay">
        {{ $tc('messages.addProduct') }}
        <PlusCircleIcon />
      </div>
    </div>
  </AcquisitionsManagerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { flexCenter } from '@styles/mixins'
import { PlusCircleIcon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'

import OrganizationAcquisitionListMolecule from './Molecules/OrganizationAcquisitionListMolecule'

import DELETE_ONE_ACQUISITION_MUTATION from '#/graphql/acquisition/deleteOneAcquisition.gql'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'

const AcquisitionsManagerStyled = styled('div')`
  display: grid;
  grid-template-areas: ${({ isAnyAcquisition }) =>
    `"title"
    "${isAnyAcquisition ? 'acquisitions' : 'acquisitions-placeholder'}"
    "create"`};
  grid-template-columns: 1fr;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  background: ${props => chroma(props.theme.colors.midnightBlue).darken(1.0).alpha(0.5).css()};

  .title {
    ${flexCenter};
    justify-content: flex-start;
    padding: 0 1rem;
    grid-area: title;
    font-size: 1.2rem;
  }

  .acquisitions-placeholder {
    grid-area: acquisitions-placeholder;
    padding: 0 1rem;
  }

  .acquisitions {
    grid-area: acquisitions;
  }

  .create {
    grid-area: create;

    .add-icon {
      cursor: pointer;
      ${flexCenter};
      height: 40px;
      background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.2).css()};

      svg {
        margin-left: 0.5rem;
      }

      &:hover {
        background: ${p => chroma(p.theme.colors.archonBlue).alpha(0.5).css()};
      }
    }
  }
`

export default {
  props: {
    organization: {
      type: Object,
    },
  },
  components: {
    AcquisitionsManagerStyled,
    PlusCircleIcon,
    OrganizationAcquisitionListMolecule,
  },
  computed: {
    organizationID() {
      return this.$route.params.id
    },
    acquisitions() {
      return this.organization.acquisitions || []
    },
    isAnyAcquisition() {
      return this.acquisitions.length > 0
    },
  },
  methods: {
    activateAddAcquisitionOverlay() {
      this.$root.$emit('activateOverlay', 'AddOrEditAcquisitionOverlay', {
        organization: this.organization,
        mode: 'add',
      })
    },
    activateEditAcquisitionOverlay(acquisitionId) {
      this.$root.$emit('activateOverlay', 'AddOrEditAcquisitionOverlay', {
        organization: this.organization,
        acquisition: this.acquisitions.find(a => a.id === acquisitionId),
        mode: 'edit',
      })
    },
    confirmDelete(model) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Acquisition',
          instance: model,
          labelKey: 'name',
          onConfirm: this.delete,
          onConfirmArgs: model,
        },
        this.onCloseSettings,
      )
    },
    async deleteAcquisition({ id }) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ONE_ACQUISITION_MUTATION,
          variables: {
            where: {
              id: id,
            },
          },
          update: store => {
            const { organization } = store.readQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.organizationID,
                },
              },
            })
            organization.acquisitions = organization.acquisitions.filter(item => item.id !== id)
            store.writeQuery({
              query: ORGANIZATION_QUERY,
              variables: {
                where: {
                  id: this.organizationID,
                },
              },
              data: {
                organization,
              },
            })
          },
        })

        FlashMessages.$emit('success', this.$t('messages.entityDeletedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
          timeout: 3000,
        })

        this.$root.$emit('closeOverlay')
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    confirmDeleteAcquisition(id) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Acquisition',
        onConfirm: this.deleteAcquisition,
        onConfirmArgs: {
          id,
        },
      })
    },
  },
}
</script>
