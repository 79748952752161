<template>
  <ModelEditor
    v-if="organization"
    :title="title"
    :model="organization"
    :errors="errors"
    :loading="$apollo.loading"
    :canSave="isDirty"
    :canCancel="isDirty"
    @save="save"
    @cancel="restore"
  >
    <!-- TODO: Fix search of assets -->
    <OrganizationStyled v-if="organization">
      <AutoForm :model.sync="organization" :modelName="'Organization'" :filterFields="filterFields" />
      <ModelAssociator
        :title="'Assets'"
        :models="assets"
        :sourceModelName="'organization'"
        :targetModelName="'asset'"
        :targetModelNamePlural="'machines'"
        :whereSearchInputType="'MachineFilterInput'"
        :canBulkAddAssets="true"
        @connect="addAsset"
        @disconnect="confirmAssetDeletion"
      >
        <template slot="bulkAddAssets">
          <PasteIds @refetch="refetch" :mode="'addAssetsToOrganization'"></PasteIds>
        </template>
      </ModelAssociator>
      <MembershipManager
        :title="'Members'"
        :memberships="organization.memberships"
        :options="membershipMananagerOptions"
        :targetId="organizationId"
        :subsidiaries="organization.subsidiaries"
      />
      <SubsidiariesManager :organization="organization" />
      <ConfidentialClientManager :title="'Confidential Clients'" :targetId="organizationId" />
      <AcquisitionsManager :organization="organization" />
    </OrganizationStyled>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { get, some, join, filter, map } from 'lodash'
import isEqual from 'lodash/isEqual'

import ModelEditor from '@/components/misc/ModelEditor'
import { AutoForm, ModelAssociator, MembershipManager, ConfidentialClientManager, SubsidiariesManager, PasteIds } from '@common/components'
import { FlashMessages } from '@common/singletons'
import AcquisitionsManager from './EditOrganization/AcquisitionsManagerOrganism'
import ORGANIZATION_QUERY from '#/graphql/organizations/show.gql'
import UPDATE_ORGANIZATION_MUTATION from '#/graphql/organizations/updateOrganization.gql'
import DELETE_ASSET_FROM_ORG_SUBSIDIARY_MUTATION from '#/graphql/organizations/deleteAssetFromOrganizationAndSubsidiary.gql'

const OrganizationStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
`

export default {
  components: {
    OrganizationStyled,
    ModelEditor,
    ModelAssociator,
    MembershipManager,
    SubsidiariesManager,
    AutoForm,
    ConfidentialClientManager,
    AcquisitionsManager,
    PasteIds,
  },
  data() {
    return {
      errors: [],
      organization: null,
      organizationLocal: null,
      disabledFields: [],
      filterFields: ['memberships', 'assets', 'acquisitions'],
      membershipMananagerOptions: {
        enumName: 'OrganizationMembershipRoleEnum',
        mode: 'user',
      },
    }
  },
  computed: {
    title() {
      return `Organization ${this.organization?.name ?? ''}`
    },
    organizationId() {
      return this.organization?.id ?? null
    },
    isDirty() {
      return !isEqual(this.organization, this.organizationLocal)
    },
    assets() {
      return get(this.organization, 'assets', []).map(asset => ({
        ...asset,
        label: asset.name,
      }))
    },
  },
  methods: {
    refetch() {
      this.$apollo.queries.organization.refetch()
    },
    confirmAssetDeletion({ id, name, __typename }) {
      const subsidiariesWithAsset = filter(this.organization.subsidiaries, subsidiary => some(subsidiary.assets, { id }))

      const subsidiaries = join(map(subsidiariesWithAsset, 'name'), ', ')

      const isAssetBeingUsedInSubsidiary = subsidiariesWithAsset.length > 0
      name = isAssetBeingUsedInSubsidiary ? subsidiaries : name
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: isAssetBeingUsedInSubsidiary ? 'AssetWithInSubsidiary' : 'Asset',
        instance: { name },
        labelKey: 'name',
        onConfirm: () => {
          isAssetBeingUsedInSubsidiary
            ? this.deleteAssetFromOrganizationAndSubsidiary(id, this.$route.params.id)
            : this.deleteAssetFromOrganization(id, __typename, 'deleteMany', 'assets')
        },
        onConfirmArgs: {},
      })
    },
    async save() {
      const data = { ...this.organization }
      delete data.id
      delete data.__typename
      this.filterFields.forEach(key => {
        delete data[key]
      })
      this.disabledFields.forEach(key => {
        delete data[key]
      })
      data.subsidiaries = []
      data.dashboardSharingEnabledForAllUsers = data.dashboardSharingEnabledForAllUsers && data.nonAdminUsersCanSeeOtherUsers

      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_MUTATION,
          variables: {
            where: {
              id: this.$route.params.id,
            },
            data,
          },
        })

        this.organizationLocal = res?.data.updateOrganization ?? null
        FlashMessages.$emit('success', `Organization ${this.organizationLocal.name} saved successfully`, {
          timeout: 3000,
        })
        this.organizationLocal = { ...this.organization, ...this.organizationLocal }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async restore() {
      this.organization = this.organizationLocal
    },
    async addOrDeleteAsset(id, type, mode, entity) {
      const organizationID = this.$route.params.id
      if (!organizationID || !entity || !mode) {
        return
      }
      //create mode mandates a assetType in schema. to delete the assets added though script, the assettype match needs to be skipped.
      //The below if-else will be removed in the meta-store version.
      let assignedAssets
      if (mode === 'delete' || mode === 'deleteMany') {
        assignedAssets = {
          [mode]: [
            {
              assetId: id,
            },
          ],
        }
      } else {
        assignedAssets = {
          [mode]: [
            {
              assetId: id,
              assetType: type,
            },
          ],
        }
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_MUTATION,
          variables: {
            where: {
              id: organizationID,
            },
            data: {
              assignedAssets,
            },
          },
        })
        if (res) {
          if (mode === 'deleteMany') {
            FlashMessages.$emit('success', `Asset deleted successfully`, {
              timeout: 3000,
            })
          }
          this.refetch()
          this.$root.$emit('closeOverlay')
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async addAsset({ id, __typename }) {
      return this.addOrDeleteAsset(id, __typename, 'create', 'assets')
    },
    async deleteAssetFromOrganizationAndSubsidiary(assetId, organizationId) {
      try {
        const response = await this.$apollo.mutate({
          mutation: DELETE_ASSET_FROM_ORG_SUBSIDIARY_MUTATION,
          variables: {
            assetId,
            organizationId,
          },
        })
        if (response) {
          FlashMessages.$emit('success', `Asset deleted successfully`, {
            timeout: 3000,
          })
          this.refetch()
          this.$root.$emit('closeOverlay')
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async deleteAssetFromOrganization(id, __typename, mode, entity, assetName) {
      return this.addOrDeleteAsset(id, __typename, mode, entity, assetName)
    },
  },
  apollo: {
    organization: {
      query: ORGANIZATION_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      fetchPolicy: 'network-only',
      manual: true,
      result({ data }) {
        this.organization = data?.organization
        this.organizationLocal = data?.organization
      },
    },
  },
}
</script>
