<template>
  <OrganizationAcquisitionListStyled>
    <div class="acquisition-row">
      <div class="cell">
        <strong>{{ $tc('product', 1) }}</strong>
      </div>
      <div class="cell">
        <strong>{{ $tc('tier', 2) }}</strong>
      </div>
      <div class="cell assets">
        <strong>{{ $tc('status', 2) }}</strong>
      </div>
      <div class="cell spacer"></div>
    </div>
    <div class="acquisition-row" v-for="acquisition in acquisitions" :key="acquisition.id">
      <div class="cell name">
        {{ acquisition?.productTierLevel?.product.name }}
      </div>
      <div class="cell">
        {{ acquisition?.productTierLevel?.tierLevel }}
      </div>
      <div class="cell assets">
        {{ acquisition?.status }}
      </div>
      <div class="cell actions">
        <button @click="$emit('editAcquisition', acquisition.id)">
          <Edit2Icon />
        </button>
        <button @click="$emit('deleteAcquisition', acquisition.id)">
          <TrashIcon />
        </button>
      </div>
    </div>
  </OrganizationAcquisitionListStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { Edit2Icon, TrashIcon } from 'vue-feather-icons'
import { flexCenter, buttonReset } from '@styles/mixins'

const OrganizationAcquisitionListStyled = styled('div')`
  .acquisition-row {
    display: grid;
    grid-template-columns: minmax(200px, max-content) 100px 150px 80px;
    grid-auto-rows: minmax(2rem, min-content);
    grid-gap: 1rem;
    > .title {
      display: flex;
      align-items: center;
      grid-column: span 4;
      font-size: 1.3rem;
      padding: 0.5rem;
      border-bottom: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
    }
    > .cell {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      word-break: break-all;
    }

    button,
    a {
      ${buttonReset};
      ${flexCenter};
    }
  }
`

export default {
  components: {
    OrganizationAcquisitionListStyled,
    Edit2Icon,
    TrashIcon,
  },
  props: {
    acquisitions: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
