var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AcquisitionsManagerStyled', {
    attrs: {
      "isAnyAcquisition": _vm.isAnyAcquisition
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$tc('acquisition', 2)) + " ")]), _vm.isAnyAcquisition ? _c('div', {
    staticClass: "acquisitions"
  }, [_c('OrganizationAcquisitionListMolecule', {
    attrs: {
      "acquisitions": _vm.acquisitions
    },
    on: {
      "deleteAcquisition": _vm.confirmDeleteAcquisition,
      "editAcquisition": _vm.activateEditAcquisitionOverlay
    }
  })], 1) : _c('div', {
    staticClass: "acquisitions-placeholder"
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.noAcquisition')) + " ")]), _c('div', {
    staticClass: "create"
  }, [_c('div', {
    staticClass: "add-icon",
    on: {
      "click": _vm.activateAddAcquisitionOverlay
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('messages.addProduct')) + " "), _c('PlusCircleIcon')], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }