var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OrganizationAcquisitionListStyled', [_c('div', {
    staticClass: "acquisition-row"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('product', 1)))])]), _c('div', {
    staticClass: "cell"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('tier', 2)))])]), _c('div', {
    staticClass: "cell assets"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$tc('status', 2)))])]), _c('div', {
    staticClass: "cell spacer"
  })]), _vm._l(_vm.acquisitions, function (acquisition) {
    var _acquisition$productT, _acquisition$productT2;
    return _c('div', {
      key: acquisition.id,
      staticClass: "acquisition-row"
    }, [_c('div', {
      staticClass: "cell name"
    }, [_vm._v(" " + _vm._s(acquisition === null || acquisition === void 0 ? void 0 : (_acquisition$productT = acquisition.productTierLevel) === null || _acquisition$productT === void 0 ? void 0 : _acquisition$productT.product.name) + " ")]), _c('div', {
      staticClass: "cell"
    }, [_vm._v(" " + _vm._s(acquisition === null || acquisition === void 0 ? void 0 : (_acquisition$productT2 = acquisition.productTierLevel) === null || _acquisition$productT2 === void 0 ? void 0 : _acquisition$productT2.tierLevel) + " ")]), _c('div', {
      staticClass: "cell assets"
    }, [_vm._v(" " + _vm._s(acquisition === null || acquisition === void 0 ? void 0 : acquisition.status) + " ")]), _c('div', {
      staticClass: "cell actions"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.$emit('editAcquisition', acquisition.id);
        }
      }
    }, [_c('Edit2Icon')], 1), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.$emit('deleteAcquisition', acquisition.id);
        }
      }
    }, [_c('TrashIcon')], 1)])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }